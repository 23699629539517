import {Clip, Drawing, TimeStampMessage} from '@/store/models/Project'
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {RootState} from '@/store'
import log from 'loglevel'
import {isCommentAnnotation} from '@/common/utils'
import {v4} from 'uuid'
import {clipApi} from '@/api/ClipApi'
import {AnnotationType} from '@/store/models/AnnotationType'
import {getProviderConfig} from '@/common/player'
import {CanvasMode} from '@/store/models/CanvasMode'
import {dataUrlFromSnapshot} from '@/components/fabric/DrawingProcessor'

export interface ProjectState {
  clips: Clip[],
  currentClipId: string
  rollingAnnotations: TimeStampMessage[]
  canvasMode: CanvasMode
}

@Module({namespaced: true, name: 'project'})
export class ProjectVuex extends VuexModule<any, RootState> implements ProjectState {
  clips: Clip[] = []
  currentClipId = ''
  rollingAnnotations: TimeStampMessage[] = []
  canvasMode: CanvasMode = 'deactivated'

  get currentClip(): Clip | undefined {
    if (!this.currentClipId) {
      return undefined
    }
    return this.clips.find(p => p.id === this.currentClipId)
  }

  get findDrawing(): (id: string) => Drawing | undefined {
    return (id: string) => {
      if (!this.currentClip) {
        return undefined
      }
      return this.currentClip.drawings.find(d => d.id === id)
    }
  }

  get currentClipChangeList(): TimeStampMessage[] {
    const p = this.currentClip
    if (!p) {
      return []
    }
    return [...p.drawings, ...p.comments].sort((a, b) => {
      if (a.timeInSeconds > b.timeInSeconds) {
        return 1
      } else if (a.timeInSeconds === b.timeInSeconds) {
        return 0
      }
      return -1
    })
  }

  @Mutation
  public setCanvasMode(mode: CanvasMode): void {
    this.canvasMode = mode
  }

  @Mutation
  public setClipTitle(payload: { clipId: string, title: string }): void {
    const clip = this.clips.find(p => p.id === payload.clipId)
    if (!clip) {
      return
    }
    this.clips = [
      ...this.clips.filter(c => c.id !== payload.clipId),
      {
        ...clip,
        title: payload.title
      }
    ]
  }

  @Mutation
  public setClip(payload: { clip: Clip }): void {
    this.clips = [
      ...this.clips.filter(c => c.id !== payload.clip.id),
      payload.clip
    ]
    this.currentClipId = payload.clip.id
    this.rollingAnnotations = []
  }

  @Mutation
  public addToRollingAnnotations(message: TimeStampMessage): void {
    this.rollingAnnotations = [
      ...this.rollingAnnotations.filter(r => r.id !== message.id),
      message
    ]
  }

  @Mutation
  public removeFromRollingAnnotations(message: TimeStampMessage): void {
    this.rollingAnnotations = this.rollingAnnotations.filter(r => r.id !== message.id)
  }

  @Mutation
  public resetRollingAnnotations(): void {
    this.rollingAnnotations = []
  }

  @Mutation
  public setComment(payload: { clipId: string, time: number, comment: string, commentId: string, userName: string }): void {
    const clip = this.clips.find(p => p.id === payload.clipId)
    if (!clip) {
      log.error('Could not add comment to clip: no clip found for id')
      return
    }
    if (payload.comment) {
      this.clips = [
        ...this.clips.filter(p => p.id !== payload.clipId),
        {
          id: clip.id,
          drawings: clip.drawings,
          comments: [
            ...clip.comments.filter(c => c.id !== payload.commentId),
            {
              id: payload.commentId,
              type: 'comment',
              text: payload.comment,
              timeInSeconds: payload.time,
              userName: payload.userName
            }
          ],
          link: clip.link,
          title: clip.title
        }
      ]
    } else {
      this.clips = [
        ...this.clips.filter(p => p.id !== payload.clipId),
        {
          id: clip.id,
          drawings: clip.drawings,
          comments: [
            ...clip.comments.filter(c => c.id !== payload.commentId)
          ],
          link: clip.link,
          title: clip.title
        }
      ]
    }
  }

  @Mutation
  public setDrawing(payload: {
    clipId: string, time: number, snapshot: string,
    snapshotId: string, hasObjects: boolean, comment: string,
    userName: string, dataUrl: string
  }): void {
    const clip = this.clips.find(p => p.id === payload.clipId)
    if (!clip) {
      log.error('Could not add drawing snapshot to clip: no clip with id')
      return
    }

    if (payload.hasObjects) {
      this.clips = [
        ...this.clips.filter(p => p.id !== payload.clipId),
        {
          id: clip.id,
          drawings: [
            ...clip.drawings.filter(c => c.id !== payload.snapshotId),
            {
              id: payload.snapshotId,
              type: 'drawing',
              snapshot: payload.snapshot,
              timeInSeconds: payload.time,
              text: payload.comment,
              userName: payload.userName,
              dataUrl: payload.dataUrl
            }
          ],
          comments: clip.comments,
          link: clip.link,
          title: clip.title
        }
      ]
    } else {
      this.clips = [
        ...this.clips.filter(p => p.id !== payload.clipId),
        {
          id: clip.id,
          drawings: [
            ...clip.drawings.filter(c => c.id !== payload.snapshotId)
          ],
          comments: clip.comments,
          link: clip.link,
          title: clip.title
        }
      ]
    }
  }

  @Mutation
  public deleteAnnotation(payload: { clipId: string, annotationId: string }): void {
    const clip = this.clips.find(p => p.id === payload.clipId)
    if (!clip) {
      log.error('Could not add drawing snapshot to clip: no clip with id')
      return
    }

    this.clips = [
      ...this.clips.filter(p => p.id !== payload.clipId),
      {
        id: clip.id,
        drawings: [
          ...clip.drawings.filter(c => c.id !== payload.annotationId)
        ],
        comments: [
          ...clip.comments.filter(c => c.id !== payload.annotationId)
        ],
        link: clip.link,
        title: clip.title
      }
    ]
  }

  @Action
  public createNewClip(payload: { href: string }): Promise<{ clipId: string }> {
    const clipId = v4()
    const [provider, code, parsedUrl] = getProviderConfig(payload.href)

    return clipApi.createClip({
      id: clipId,
      videoUrl: parsedUrl
    }).then(() => {
      const clip: Clip = {
        id: clipId,
        link: parsedUrl,
        title: '',
        comments: [],
        drawings: []
      }
      this.context.commit('setClip', {clip})
      return {clipId}
    })
  }

  @Action({rawError: true})
  public loadClip(payload: { id: string }): Promise<void> {
    return clipApi.getClip(payload.id)
      .then(clip => {
        if (!clip) {
          log.error(`No clip found for id ${payload.id}`)
          return Promise.reject(new Error(`No clip found for id ${payload.id}`))
        }

        this.context.commit('setClip', {clip})
      })
  }

  @Action
  public storeComment(payload: { comment: string, commentId: string }): Promise<void> {
    if (!payload.commentId) {
      log.warn('no commentid when storing comment: ' + payload.comment)
      this.context.commit('setCanvasMode', 'deactivated' as CanvasMode)
      return Promise.resolve()
    }

    const timeInSeconds = this.context.rootState.video.currentTime
    const userName = this.context.rootState.profile.name

    return clipApi.saveComment({
      clipId: this.currentClipId,
      commentId: payload.commentId,
      text: payload.comment,
      timeInSeconds: timeInSeconds
    }).then(() => {
      this.context.commit('setComment', {
        clipId: this.currentClipId,
        time: timeInSeconds,
        comment: payload.comment,
        commentId: payload.commentId,
        userName: userName
      })

      this.context.commit('comment/reset', undefined, {root: true})
      this.context.commit('setCanvasMode', 'deactivated' as CanvasMode)
    })
  }

  @Action({rawError: true})
  public storeSnapshot(payload: { snapshot: string, snapshotId: string, hasObjects: boolean, comment: string, dataUrl: string, canvasWidth: number, canvasHeight: number }): Promise<void> {
    const currentTime = this.context.rootState.video.currentTime
    const userName = this.context.rootState.profile.name

    return clipApi.saveDrawing({
      clipId: this.currentClipId,
      drawingId: payload.snapshotId,
      snapshot: JSON.stringify(payload.snapshot),
      hasObjects: payload.hasObjects,
      timeInSeconds: currentTime,
      canvasWidth: payload.canvasWidth,
      canvasHeight: payload.canvasHeight
    }).then(() => {
      this.context.commit('setDrawing', {
        clipId: this.currentClipId,
        time: currentTime,
        snapshot: payload.snapshot,
        snapshotId: payload.snapshotId,
        comment: payload.comment,
        hasObjects: payload.hasObjects,
        userName: userName,
        dataUrl: payload.dataUrl
      })

      this.context.commit('comment/reset', undefined, {root: true})
      this.context.commit('setCanvasMode', 'deactivated' as CanvasMode)
    })
  }

  @Action({rawError: true})
  public async deleteAnnotationFromCurrentClip(payload: { id: string, type: AnnotationType }): Promise<void> {
    switch (payload.type) {
      case 'comment':
        await clipApi.deleteComment(this.currentClipId, payload.id)
        break
      case 'drawing':
        await clipApi.deleteDrawing(this.currentClipId, payload.id)
        break
      default:
        log.error(`Cannot delete annotation with id ${payload.id}`)
        return
    }

    this.context.commit('draw/reset', undefined, {root: true})
    this.context.commit('deleteAnnotation', {
      clipId: this.currentClipId,
      annotationId: payload.id
    })
  }

  @Action
  public rollPlaybackAnnotations(payload: { time: number }): Promise<void> {
    const messages: TimeStampMessage[] = this.context.getters.currentClipChangeList
    const videoTime = Math.floor(payload.time)
    messages.map(m => {
      const messageTime = Math.floor(m.timeInSeconds)
      if (videoTime >= messageTime && videoTime <= messageTime + 2) {
        this.context.commit('addToRollingAnnotations', m)
      } else {
        this.context.commit('removeFromRollingAnnotations', m)
      }
    })
    return Promise.resolve()
  }

  @Action
  public async setTitle(payload: { clipId: string, title: string }): Promise<void> {
    try {
      await clipApi.setTitle({title: payload.title, clipId: payload.clipId})
      this.context.commit('setClipTitle', {title: payload.title, clipId: payload.clipId})
    } catch (e) {
      log.error('Could not set title:', e)
    }
  }
}
