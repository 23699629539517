import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {RootState} from '@/store'
import {CanvasMode} from '@/store/models/CanvasMode'

export interface CommentState {
  currentComment: string
  currentCommentId: string
}

@Module({namespaced: true, name: 'comment'})
export class CommentVuex extends VuexModule<any, RootState> implements CommentState {
  currentComment = ''
  currentCommentId = ''

  @Mutation
  public reset() {
    this.currentComment = ''
    this.currentCommentId = ''
  }

  @Mutation
  public setCurrentComment(payload: { comment: string }): void {
    this.currentComment = payload.comment
  }

  @Mutation
  public setCurrentCommentId(payload: { commentId: string }): void {
    this.currentCommentId = payload.commentId
  }

  @Action
  public cancelAnnotating(): Promise<void> {
    this.context.commit('reset')
    this.context.commit('project/setCanvasMode', 'deactivated' as CanvasMode, {root: true})
    return Promise.resolve()
  }

  @Action
  public stopAnnotating(): Promise<void> {
    return this.context.dispatch('project/storeComment', {
      comment: this.currentComment, commentId: this.currentCommentId
    }, {root: true})
  }
}
