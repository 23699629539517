import {Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {RootState} from '@/store'
import {getInitials} from '@/common/utils'
import {v4} from 'uuid'

export interface ProfileState {
  name: string
  userId: string
}

@Module({namespaced: true, name: 'profile'})
export class Profile extends VuexModule<any, RootState> implements ProfileState {
  name = ''
  userId = ''

  get initials(): string {
    return getInitials(this.name)
  }

  @Mutation
  setName(name: string): void {
    this.name = name
  }

  @Mutation
  setUserId(): void {
    if (!this.userId) {
      this.userId = v4()
    }
  }
}
