import seedrandom from 'seedrandom'
import log from 'loglevel'

// type tColor = 'gray' | 'red' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink'
// const choices: tColor[] = ['yellow', 'blue', 'gray', 'green', 'indigo', 'pink', 'red', 'purple']
type tColor = 'indigo'
const choices: tColor[] = ['indigo']

export const tailwindColor = (value: string): tColor => {
  const len = choices.length
  const rng = seedrandom(value)
  const index = Math.floor(rng() * len)
  return choices[index]
}

export const colorClasses = (userName: string): {[key: string]: boolean} => {
  const color = tailwindColor(userName)
  return {
    [`bg-${color}-600`]: true,
    [`hover:bg-${color}-700`]: true
  }
}
