import mitt, {Emitter} from 'mitt'
import {DrawModule} from '@/store'

export const emitter: Emitter = mitt()

export const Events = {
  VIDEO_PLAY: 'play',
  VIDEO_PAUSE: 'pause',
  VIDEO_CHANGE_TIME: 'changeTime',
  VIDEO_CHANGE_VOLUME: 'changeVolume',
  UPDATE_DRAW_STYLE: 'updateDrawStyle',
  SNAPSHOT_DRAW_SCREEN: 'snapshotDrawingScreen',
  SNAPSHOT_DRAW_SCREEN_FINISHED: 'snapshotDrawingScreenFinished',
  SNAPSHOT_RESTORE: 'snapshotRestore',
  SNAPSHOT_CLEAR: 'snapshotClear'
}

export const handleStopDrawingAnnotate = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    emitter.on(Events.SNAPSHOT_DRAW_SCREEN_FINISHED, (event) => {
      if (event.finished) {
        resolve()
      } else {
        reject(new Error('snapshot finished with error'))
      }
    })
    DrawModule.stopAnnotating() // doesn't wait
  })
}
