import log from 'loglevel'
import {ProfileModule} from '@/store'

interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export abstract class HttpApi {
  // eslint-disable-next-line no-useless-constructor
  protected constructor(private readonly basePath: string) {
  }

  async get<T>(
    path: string,
    args: RequestInit = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'x-videohub-userid': ProfileModule.userId
      }
    }
  ): Promise<HttpResponse<T>> {
    return await this.http<T>(new Request(this.fullPath(path), args))
  }

  async post<T>(
    path: string,
    body: any,
    args: RequestInit = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-videohub-username': ProfileModule.name,
        'x-videohub-userid': ProfileModule.userId
      },
      body: JSON.stringify(body)
    }
  ): Promise<HttpResponse<T>> {
    return await this.http<T>(new Request(this.fullPath(path), args))
  }

  async put<T>(
    path: string,
    body: any,
    args: RequestInit = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'x-videohub-username': ProfileModule.name,
        'x-videohub-userid': ProfileModule.userId
      },
      body: JSON.stringify(body)
    }
  ): Promise<HttpResponse<T>> {
    return await this.http<T>(new Request(this.fullPath(path), args))
  }

  private async http<T>(
    request: RequestInfo
  ): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetch(request)

    try {
      // may error if there is no body
      response.parsedBody = await response.json()
    } catch (ex) {
    }

    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response
  }

  private fullPath(path: string): string {
    if (!path.startsWith('/')) {
      log.error('path does not start with an \'/\'')
      throw new Error('path does not start with an \'/\'')
    }
    return `${this.basePath}${path}`
  }
}
