import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {RootState} from '@/store'
import {PlayerMode} from '@/store/models/PlayerMode'
import {emitter, Events} from '@/common/events'

export interface VideoState {
  currentTime: number
  currentVolume: number
  previousVolume: number
  duration: number
  playerReady: boolean
  playerMode: PlayerMode
  isPlaying: boolean
}

@Module({namespaced: true, name: 'video'})
export class Video extends VuexModule<any, RootState> implements VideoState {
  currentTime = 0
  currentVolume = 1
  previousVolume = 0
  duration = 0
  playerReady = false
  playerMode = 'unknown' as PlayerMode

  get isPlaying(): boolean {
    return this.playerMode === 'playing'
  }

  @Mutation
  public reset(): void {
    this.currentTime = 0
    this.currentVolume = 1
    this.previousVolume = 0
    this.duration = 0
    this.playerReady = false
    this.playerMode = 'unknown' as PlayerMode
  }

  @Mutation
  public setPlayerStatus(status: PlayerMode): void {
    this.playerMode = status
  }

  @Mutation
  public setPlayerReady(ready: boolean): void {
    this.playerReady = ready
  }

  @Mutation
  public setCurrentTime(time: number): void {
    this.currentTime = time
  }

  @Mutation
  public setDuration(time: number): void {
    this.duration = time
  }

  @Mutation
  public setVolume(volume: number): void {
    this.previousVolume = this.currentVolume
    this.currentVolume = volume
  }

  @Action
  public changeCurrentTime(payload: { time: number, pause?: boolean }): Promise<void> {
    const {time, pause} = payload
    emitter.emit(Events.VIDEO_CHANGE_TIME, {time, pause})
    this.context.commit('setCurrentTime', time) // only needed when video is in paused state
    return Promise.resolve()
  }

  @Action
  public playVideo(): Promise<void> {
    emitter.emit(Events.VIDEO_PLAY) // emits event to plyr, all logic handled after event received
    return Promise.resolve()
  }

  @Action
  public pauseVideo(): Promise<void> {
    emitter.emit(Events.VIDEO_PAUSE) // emits event to plyr, all logic handled after event received
    return Promise.resolve()
  }

  @Action
  public toggleMute(): Promise<void> {
    if (this.currentVolume > 0) { // MUTE
      emitter.emit(Events.VIDEO_CHANGE_VOLUME, {volume: 0})
      this.context.commit('setVolume', 0)
    } else { // UNMUTE
      emitter.emit(Events.VIDEO_CHANGE_VOLUME, {volume: this.previousVolume})
      this.context.commit('setVolume', this.previousVolume)
    }

    return Promise.resolve()
  }

  @Action
  public changeVolume(volume: number): Promise<void> {
    if (volume > 1) {
      volume = 1
    } else if (volume < 0) {
      volume = 0
    }
    emitter.emit(Events.VIDEO_CHANGE_VOLUME, {volume: volume})
    this.context.commit('setVolume', volume)

    return Promise.resolve()
  }
}
