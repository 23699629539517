import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'

import './assets/styles/tailwind.css'
import './assets/styles/base.scss'

import VuePlyr from 'vue-plyr'
import * as log from 'loglevel'
import {getInitials, readableTime} from '@/common/utils'

if (process.env.NODE_ENV === 'production') {
  log.setDefaultLevel('warn')
} else {
  log.setDefaultLevel('trace')
}

// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: {enabled: false},
    ratio: '16:9',
    resetOnEnd: true,
    youtube: {
      noCookie: true,
      rel: 0,
      showInfo: 0
    }
  },
  // https://github.com/sampotts/plyr#events
  emit: ['play', 'playing', 'paused', 'ended', 'timeupdate']
})

Vue.config.productionTip = false

Vue.filter('initials', (value: string) => getInitials(value))
Vue.filter('formatTime', (value: number) => readableTime(value))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
