import {HttpApi} from '@/api/HttpApi'
import {API_HOST} from '@/common/variables'

export class ConfigApi extends HttpApi {
  constructor() {
    super(API_HOST)
  }

  async warmupLambda(): Promise<void> {
    await this.get<{message: string}>('/status')
  }
}

export const configApi = new ConfigApi()
