import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {DrawStyle} from '@/components/fabric/DrawStyle'
import {CursorMode} from '@/store/models/DrawModes'
import {emitter, Events} from '@/common/events'
import {RootState} from '@/store'
import * as log from 'loglevel'
import {Drawing} from '@/store/models/Project'
import {CanvasMode} from '@/store/models/CanvasMode'

export interface DrawState {
  drawStyle: DrawStyle
  cursorMode: CursorMode
  canvasWidth: number
  canvasHeight: number
}

@Module({namespaced: true, name: 'draw'})
export class Draw extends VuexModule<any, RootState> implements DrawState {
  drawStyle = DrawStyle.None
  cursorMode = CursorMode.Draw
  canvasHeight = 405
  canvasWidth = 720

  get canDelete() {
    return this.cursorMode === CursorMode.Select
  }

  @Mutation
  public setDrawStyle(mode: DrawStyle): void {
    this.drawStyle = mode
    emitter.emit(Events.UPDATE_DRAW_STYLE)
  }

  @Mutation
  public setCursorMode(mode: CursorMode): void {
    this.cursorMode = mode
  }

  @Mutation
  public reset(): void {
    this.drawStyle = DrawStyle.None

    emitter.emit(Events.SNAPSHOT_CLEAR)
  }

  // @Action
  // public cancelAnnotating(): Promise<void> {
  //   throw new Error('not implemented')
  // }

  /**
   * Doesn't wait !
   * Use `handleStopDrawingAnnotate` for awaiting event
   */
  @Action
  public stopAnnotating(): Promise<void> {
    // Disable drawing state
    this.context.commit('setDrawStyle', DrawStyle.None)

    emitter.emit(Events.SNAPSHOT_DRAW_SCREEN, {comment: '[snapshot]'}) // will trigger 'storeSnapshot' action
    return Promise.resolve()
  }

  @Action
  public async startAnnotatingFromSnapshot(id: string): Promise<void> {
    const findDrawing = this.context.rootGetters['project/findDrawing'] as (id: string) => Drawing | undefined

    const drawing = findDrawing(id)
    if (!drawing) {
      const msg = `cannot restore snapshot: no drawing found with id ${id}`
      log.error(msg)
      return Promise.reject(msg)
    }

    // Change time & pause
    await this.context.dispatch('video/changeCurrentTime', {time: drawing.timeInSeconds, pause: true}, {root: true})
    // Show drawing screen
    this.context.commit('project/setCanvasMode', 'activated-draw' as CanvasMode, {root: true})
    // Set drawing state
    this.context.commit('setDrawStyle', DrawStyle.Polyline)

    emitter.emit(Events.SNAPSHOT_RESTORE, {id, snapshot: drawing.snapshot})

    return Promise.resolve()
  }
}
