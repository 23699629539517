







import {Component, Vue} from 'vue-property-decorator'
import NavbarComponent from '@/components/Navbar.vue'
import ShareLinkModalComponent from '@/components/modals/ShareLinkModal.vue'

@Component({
  components: {
    navbar: NavbarComponent,
    'share-link-modal': ShareLinkModalComponent
  }
})
export default class AppLayout extends Vue {

}
