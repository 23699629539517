import {v4} from 'uuid'
import {fabric} from 'fabric'

export class DrawingProcessor {
  private readonly canvas: fabric.Canvas

  constructor(options: {height: number, width: number}) {
    this.canvas = new fabric.Canvas('c', {
      height: options.height,
      width: options.width
    })
  }

  /**
   * https://stackoverflow.com/questions/21177934/reset-canvas-and-reload-with-json-in-fabric-js
   * @param snapshot
   */
  public dataUrlFromSnapshot(snapshot: string): string {
    this.canvas.clear()
    this.canvas.loadFromJSON(snapshot, this.canvas.renderAll.bind(this.canvas))
    const dataUrl = this.canvas.toDataURL({quality: 0.2})
    this.canvas.clear()
    return dataUrl
  }
}

export const dataUrlFromSnapshot = (options: {snapshot: string, width: number, height: number}) => {
  const {snapshot, width, height} = options
  const canvas = new fabric.Canvas('c', {
    height: height,
    width: width
  })
  canvas.loadFromJSON(snapshot, canvas.renderAll.bind(canvas))
  const dataUrl = canvas.toDataURL({quality: 0.2})
  canvas.clear()
  return dataUrl
}
