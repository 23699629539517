



import {Component, Vue} from 'vue-property-decorator'
import {ProfileModule} from '@/store'

@Component({})
export default class App extends Vue {
  mounted(): void {
    ProfileModule.setUserId()
  }
}
