import {Clip} from '@/store/models/Project'
import {dataUrlFromSnapshot} from '@/components/fabric/DrawingProcessor'

interface CommentResponse {
  id: string
  text: string
  userName: string
  timeInSeconds: number
}

interface DrawingResponse {
  id: string
  snapshot: string
  userName: string
  timeInSeconds: number
  canvasWidth: number
  canvasHeight: number
}

export interface ClipResponse {
  id: string
  title: string
  videoUrl: string
  comments: CommentResponse[]
  drawings: DrawingResponse[]
}

export const toClip = (r: ClipResponse): Clip => ({
  id: r.id,
  title: r.title,
  link: r.videoUrl,
  drawings: r.drawings.map(d => ({
    id: d.id,
    type: 'drawing',
    text: '[snapshot]',
    snapshot: d.snapshot,
    timeInSeconds: d.timeInSeconds,
    userName: d.userName,
    dataUrl: dataUrlFromSnapshot({snapshot: d.snapshot, width: d.canvasWidth, height: d.canvasHeight})
  })),
  comments: r.comments.map(c => ({
    id: c.id,
    type: 'comment',
    text: c.text,
    timeInSeconds: c.timeInSeconds,
    userName: c.userName
  }))
})
