
















































import {Component, Vue} from 'vue-property-decorator'
import {AppModule} from '@/store'

@Component({})
export default class ModalComponent extends Vue {
  get openModal(): boolean {
    return AppModule.shareModelOpen
  }
}
