





























import {Component, Vue} from 'vue-property-decorator'
import {ProfileModule} from '@/store'
import {RouteNames} from '@/router'
import {colorClasses} from '@/common/styles'

@Component({
  name: 'navbar'
})
export default class NavbarComponent extends Vue {
  public showNameBox = false

  get initials(): string {
    return ProfileModule.initials
  }

  get colors(): {[key: string]: boolean} {
    return colorClasses(this.name)
  }

  public toHome(): void {
    this.$router.push({name: RouteNames.HOME})
  }

  public toggleNameBox(): void {
    this.showNameBox = !this.showNameBox
  }

  get name(): string {
    return ProfileModule.name
  }

  set name(n: string) {
    ProfileModule.setName(n)
  }
}
