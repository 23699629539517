import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {RootState} from '@/store'
import {configApi} from '@/api/ConfigApi'
import log from 'loglevel'

export interface AppState {
  error: unknown
  shareModelOpen: boolean
}

@Module({namespaced: true, name: 'app'})
export class App extends VuexModule<any, RootState> implements AppState {
  error = undefined
  shareModelOpen = false

  @Mutation
  setShareModelOpen(open: boolean): void {
    this.shareModelOpen = open
  }

  @Action
  async warmupLambda(): Promise<void> {
    try {
      await configApi.warmupLambda()
    } catch (e) {
      log.error('Could not call warmup endpoint')
    }
  }
}
