import {Clip, Comment, Drawing, TimeStampMessage} from '@/store/models/Project'

export const formatTime = (timeInSeconds: number): { minutes: string, seconds: string } => {
  if (!timeInSeconds) {
    return {
      minutes: '00',
      seconds: '00'
    }
  }

  const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)

  return {
    minutes: result.substr(3, 2),
    seconds: result.substr(6, 2)
  }
}

export const readableTime = (timeInSeconds: number): string => {
  const t = formatTime(timeInSeconds)
  return `${t.minutes}m ${t.seconds}s`
}

export const relativeTime = (timeInSeconds: number, duration: number): number => {
  if (!timeInSeconds || !duration) {
    return 0
  }

  return 100.0 * timeInSeconds / duration
}

export const isDrawingAnnotation = (message: TimeStampMessage): message is Drawing => {
  return (message as Drawing).snapshot !== undefined
}

export const isCommentAnnotation = (message: TimeStampMessage): message is Comment => {
  return (message as Drawing).snapshot === undefined
}

export const isCommentFromClip = (clip: Clip | undefined, annotationId: string): boolean => {
  if (!clip) {
    return false
  }
  return clip.comments.find(c => c.id === annotationId) !== undefined
}

export const isDrawingFromClip = (clip: Clip | undefined, annotationId: string): boolean => {
  if (!clip) {
    return false
  }
  return clip.drawings.find(c => c.id === annotationId) !== undefined
}

export const getInitials = (name: string): string => {
  if (!name) {
    return 'GU'
  }
  return name.substr(0, 2).toUpperCase()
}

export const addjustTime = (timeInSeconds: number): number => {
  const margin = 0.1
  if (timeInSeconds < margin) {
    return margin
  }
  return timeInSeconds
}
