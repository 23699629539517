import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import AppLayout from '@/views/layouts/AppLayout.vue'

Vue.use(VueRouter)

export const RouteNames = {
  HOME: 'Home',
  CLIP_DETAIL: 'Clip detail',
  DRAW_TEST: 'Draw test',
  STYLE_GUIDE: 'style guide',
  TEST: 'test'
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: RouteNames.HOME,
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/clip/:clipId',
    component: AppLayout,
    children: [
      {
        path: '',
        name: RouteNames.CLIP_DETAIL,
        props: true,
        component: () => import(/* webpackChunkName: "clip" */ '../views/Clip.vue')
      }
    ]
  },
  {
    path: '/draw',
    component: AppLayout,
    children: [{
      path: '',
      name: RouteNames.DRAW_TEST,
      component: () => import(/* webpackChunkName: "draw-test" */ '../views/DrawTest.vue')
    }]
  },
  {
    path: '/styleguide',
    component: AppLayout,
    children: [{
      path: '',
      name: RouteNames.STYLE_GUIDE,
      component: () => import(/* webpackChunkName: "style-guide" */ '../views/StyleGuide.vue')
    }]
  },
  {
    path: '*',
    redirect: {name: RouteNames.HOME}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
