import Plyr from 'plyr'
import log from 'loglevel'

export const parseVideoUrl = (link: string): string => {
  let result = ''
  if (!link) {
    return result
  }
  result = link.trim()

  const [provider, code] = getProviderConfig(link)

  if (provider === 'vimeo') {
    return `https://player.vimeo.com/${code}`
  }
  return result
}

export const getVideoSource = async (link: URL): Promise<Plyr.SourceInfo> => {
  const source: Plyr.Source = {
    src: link.href
  }

  const [provider, code] = getProviderConfig(link.href)
  source.provider = provider

  let type
  if (provider === 'html5') {
    type = await fetchContentType(link)
    source.type = type
  } else if (provider === 'vimeo' || provider === 'youtube') {
    source.src = code
  }

  log.debug(source)
  return {
    type: 'video',
    sources: [source]
  }
}

const youtubeRegex = new RegExp('((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$')
const vimeoRegex = new RegExp('^(http:\\/\\/|https:\\/\\/)?(player.)?(vimeo\\.com)\\/(video\\/)?([\\w\\/]+)([\\?].*)?$')

export const getProviderConfig = (link: string): [Plyr.Provider, string, string] => {
  if (!link) {
    return ['html5', '', '']
  }
  const trimmed = link.trim()
  const youtubeFound = trimmed.match(youtubeRegex)
  if (youtubeFound) {
    const code = youtubeFound[5]
    return ['youtube', code, `https://www.youtube.com/embed/${code}`]
  }
  const vimeoFound = trimmed.match(vimeoRegex)
  if (vimeoFound) {
    const code = vimeoFound[5]
    return ['vimeo', code, `https://player.vimeo.com/video/${code}`]
  }
  return ['html5', '', trimmed]
}

const fetchContentType = async (link: URL): Promise<string> => {
  try {
    const response = await fetch(link.href, {method: 'HEAD'})
    if (!response.ok) {
      return ''
    }
    const contentType = response.headers.get('Content-Type')
    switch (contentType) {
      case 'video/webm':
      case 'video/mp4':
        return contentType
      default:
        return ''
    }
  } catch (e) {
    log.error(`Could not determine MIME type of URL ${link.href}`)
    return ''
  }
}

export const getDuration = (player: Plyr): Promise<number> => {
  if (player.provider === 'vimeo') {
    return player.embed.getDuration()
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Promise.resolve(player.media.duration)
  }
}

/**
 * Does not work for youtube. See `youtube.ts` file
 * @param player
 */
export const captureVideoImage = (player: Plyr): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const media = player.media as HTMLVideoElement
  if (!media) {
    return ''
  }
  const width = media.videoWidth
  const height = media.videoHeight
  const canvas = Object.assign(document.createElement('canvas'), { width, height })
  const canvasCtx = canvas.getContext('2d')
  if (!canvasCtx) {
    log.error('could not get canvasCtx when capturing player image')
    return ''
  }

  canvasCtx.drawImage(media, 0, 0, width, height)
  const dataUrl = canvas.toDataURL('image/png')
  return dataUrl
}
