






import {Component, Ref, Vue, Watch} from 'vue-property-decorator'
import {AppModule} from '@/store'
import ModalComponent from '@/components/modals/Modal.vue'
import {API_HOST} from '@/common/variables'

@Component({
  components: {
    modal: ModalComponent
  }
})
export default class ShareLinkModalComponent extends Vue {
  @Ref('linkshare') textArea: HTMLTextAreaElement | undefined

  get openModal(): boolean {
    return AppModule.shareModelOpen
  }

  get shareLink(): string {
    return window.location.href
  }

  // @Watch('openModal')
  // public setLink(value: boolean): void {
  //   debugger
  //   if (!this.textArea) {
  //     return
  //   }
  //   if (value) {
  //     this.textArea.value = this.$route.fullPath
  //   }
  // }

  public closeModal(): void {
    AppModule.setShareModelOpen(false)
  }
}
