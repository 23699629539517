import {Clip} from '@/store/models/Project'
import {API_HOST} from '@/common/variables'
import {HttpApi} from '@/api/HttpApi'
import {CreateClipRequest} from '@/api/requests/CreateClipRequest'
import {SetCommentRequest} from '@/api/requests/SetCommentRequest'
import {SetDrawingRequest} from '@/api/requests/SetDrawingRequest'
import {ClipResponse, toClip} from '@/api/responses/ClipResponse'
import {SetTitleRequest} from '@/api/requests/SetTitleRequest'
import {DeleteDrawingRequest} from '@/api/requests/DeleteDrawingRequest'

class ClipApi extends HttpApi {
  constructor() {
    super(API_HOST)
  }

  async getClip(id: string): Promise<Clip | undefined> {
    const r = await this.get<ClipResponse>(`/clip/${id}`)
    if (r.parsedBody) {
      return toClip(r.parsedBody)
    }
    return undefined
  }

  async createClip(requestBody: CreateClipRequest): Promise<void> {
    await this.post('/clip', requestBody)
  }

  async saveComment(requestBody: SetCommentRequest): Promise<void> {
    await this.put('/clip/comment', requestBody)
  }

  async saveDrawing(requestBody: SetDrawingRequest): Promise<void> {
    await this.put('/clip/drawing', requestBody)
  }

  async setTitle(requestBody: SetTitleRequest): Promise<void> {
    await this.put('/clip/title', requestBody)
  }

  async deleteComment(clipId: string, commentId: string): Promise<void> {
    const requestBody: SetCommentRequest = {
      clipId: clipId,
      commentId: commentId,
      timeInSeconds: 0,
      text: ''
    }
    await this.put('/clip/comment', requestBody)
  }

  async deleteDrawing(clipId: string, drawingId: string): Promise<void> {
    const requestBody: DeleteDrawingRequest = {
      clipId: clipId,
      drawingId: drawingId
    }
    await this.put('/clip/drawing/remove', requestBody)
  }
}

export const clipApi = new ClipApi()
