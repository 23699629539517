import Vue from 'vue'
import Vuex from 'vuex'
import {config, getModule} from 'vuex-module-decorators'
import {Video, VideoState} from '@/store/modules/Video'
import createPersistedState from 'vuex-persistedstate'
import {Draw, DrawState} from '@/store/modules/Draw'
import {ProjectState, ProjectVuex} from '@/store/modules/Project'
import {CommentState, CommentVuex} from '@/store/modules/Comment'
import {App, AppState} from '@/store/modules/App'
import {Profile, ProfileState} from '@/store/modules/Profile'

Vue.use(Vuex)
config.rawError = true

export interface RootState {
  video: VideoState;
  draw: DrawState;
  project: ProjectState;
  comment: CommentState;
  app: AppState,
  profile: ProfileState
}

const store = new Vuex.Store<RootState>({
  modules: {
    video: Video,
    draw: Draw,
    project: ProjectVuex,
    comment: CommentVuex,
    app: App,
    profile: Profile
  },
  plugins: [createPersistedState({
    paths: ['profile']
  })]
})

export default store

const VideoModule = getModule<Video>(Video, store)
const DrawModule = getModule<Draw>(Draw, store)
const ProjectModule = getModule<ProjectVuex>(ProjectVuex, store)
const CommentModule = getModule<CommentVuex>(CommentVuex, store)
const AppModule = getModule<App>(App, store)
const ProfileModule = getModule<Profile>(Profile, store)

export {
  VideoModule,
  DrawModule,
  ProjectModule,
  CommentModule,
  AppModule,
  ProfileModule
}
